import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";

let firstRender = true;

const {
  currentSupportEmailAddress,
} = config;

const MilesWeb_SupplementalStatePrivacyNoticePage: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_SupplementalStatePrivacyNoticePage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_SupplementalStatePrivacyNoticePage -- did mount");
    firstRender = false;
  }, []);

  return (
    <Styled_body_wrap>
      <div className="privacy_top_bg display_block"></div>
      <div className="privacy_top_hero display_block"></div>

      <div className="main_content">
        <div className="privacy_title">Supplemental State Privacy Notice</div>
        <div className="privacy_update_date">Last Updated: February 13, 2025</div>

        <div className="para_content top_intro_para">
        This Notice supplements the information contained in the Privacy Policy and applies solely to individuals who reside in the States of California, Virginia, Connecticut, Colorado, and Utah (the “Supplemental States”). We adopt this Notice to comply with the California Consumer Privacy Act (as amended by the California Privacy Rights Act), the Virginia Consumer Data Protection Act, the Connecticut Data Privacy Act, the Colorado Privacy Act, the Texas Data Privacy And Security Act, or the Utah Consumer Privacy Act (the “State Privacy Laws”) and any terms defined in those laws have the same meaning when used in this Notice.
        </div>

        <div className="miles_ui_privacy_title_01">Shine the Light</div>

        <div className="para_content inner_para_01">
        Pursuant to Section 1798.83 of the California Civil Code, California residents may request certain regarding and/or opt-out of our disclosure of personal information to third parties for their direct marketing purposes. Please email <a href={`mailto:${currentSupportEmailAddress}`} target="_blank">{currentSupportEmailAddress}</a> with such requests, and note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time.
        </div>

        <div className="miles_ui_privacy_title_01">Collection of Personal Information</div>

        <div className="para_content inner_para_01">
        We may collect the personal information or sensitive personal information categories listed in the tables below. The table also lists, for each category, how we obtain the personal information and whether we sell the information or share it with third parties for cross-context behavioral advertising. 
        </div>


        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Identifiers, </span>
              such as your name, address, phone number, email address, date of birth, or similar identifiers 
              <br />
              <span className="dot_lever_2"></span>We collect this information directly from you in accordance with this Privacy Policy. 
              <br />
              <span className="dot_lever_2"></span>This information is disclosed to trusted service providers to fulfill your request or transaction and to our Partners.
              <br />
              <span className="dot_lever_2"></span>This information is sold and/or shared.
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Personal information categories listed in the California Customer Records </span>
              statute (Cal. Civ. Code § 1798.80(e)), such as birthdate, contact information, and payment information. Some personal information included in this category may overlap with other categories.
              <br />
              <span className="dot_lever_2"></span>We collect this information directly from you in accordance with this Privacy Policy.
              <br />
              <span className="dot_lever_2"></span>This information is disclosed to trusted service providers to fulfill your request or transaction and to our Partners.
              <br />
              <span className="dot_lever_2"></span>This information is sold and/or shared.
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Protected classification characteristics </span>
              under California or federal law, such as your age, race, color, ancestry, national origin, citizenship, sexual orientation, or other similar protected information.
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Commercial information, </span>
              such as records of personal property, products or services purchased, or other consuming histories or tendencies.
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 5 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Biometric information, </span>
              such as genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints.
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 6 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Internet or other similar network activity, </span>
              such as browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.
              <br />
              <span className="dot_lever_2"></span>We collect this information indirectly from you through the use of the App.
              <br />
              <span className="dot_lever_2"></span>This information is disclosed to trusted service providers to fulfill your request or transaction and to our Partners.
              <br />
              <span className="dot_lever_2"></span>This information is sold and/or shared.
            </div>
          </div>
        </div>

        {/* 7 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Geolocation data, </span>
              such as physical location or movements.
              <br />
              <span className="dot_lever_2"></span>We collect this data directly and indirectly from you through the use of the App. 
              <br />
              <span className="dot_lever_2"></span>This information is disclosed to trusted service providers to fulfill your request or transaction and to our Partners.
              <br />
              <span className="dot_lever_2"></span>This information is sold and/or shared.
            </div>
          </div>
        </div>

        {/* 8 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Sensory data, </span>
              such as audio, electronic, visual, thermal, olfactory, or similar information.
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 9 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Professional or employment-related information. </span>
              Such as current or past job history or performance evaluations.
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 10 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Non-public education information </span>
              (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)), such as educational history or degree (for applicants).
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 11 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Inferences drawn from other personal information, </span>
              such as predictions about your interests and preferences.
              <br />
              <span className="dot_lever_2"></span>We collect this information indirectly from you through the use of the App.
              <br />
              <span className="dot_lever_2"></span>This information is disclosed to trusted service providers to fulfill your request or transaction and to our Partners.
              <br />
              <span className="dot_lever_2"></span>This information is sold and/or shared.
            </div>
          </div>
        </div>

        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <div className="miles_ui_para_with_dot_main_content without_dot">
              <span className="with_dot_title">Sensitive Personal Information</span>
            </div>
          </div>
        </div>

        {/* 12 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Government identifiers (social security, driver&apos;s license, state identification card, or passport number)</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 14 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Complete account access credentials (user names, account numbers, or card numbers combined with required access/security code or password)</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 15 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Precise geolocation</span>
              <br />
              <span className="dot_lever_2"></span>We sell and/or share this data. Please see below for more information about your rights.
            </div>
          </div>
        </div>

        {/* 16 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Racial or ethnic origin</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 17 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Religious or philosophical beliefs</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 18 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Union membership</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 19 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Genetic data</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 20 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Mail, email, or text messages contents not directed to us</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 21 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Unique identifying biometric information</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        {/* 22 */}
        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              <span className="with_dot_title">Health, sex life, or sexual orientation information</span>
              <br />
              <span className="dot_lever_2"></span>We do not collect this data.
            </div>
          </div>
        </div>

        <div className="para_content inner_para_01">
        Personal information does not include: publicly available information lawfully made available from government records, deidentified or aggregated consumer information, or information excluded from the applicable State Privacy Law.
        </div>

        <div className="miles_ui_privacy_title_01">Retention Period</div>
        <div className="para_content inner_para_01">
        We only maintain your information as long as necessary to fulfill our legitimate business purposes.
        </div>

        <div className="miles_ui_privacy_title_01">Use of Personal Information</div>
        <div className="para_content inner_para_01">
        We may use or disclose the personal information collected in accordance with our <a href="/privacy" target="_blank">Privacy Policy</a>.
        </div>

        <div className="miles_ui_privacy_title_01">Your Rights</div>
        
        <div className="miles_ui_privacy_sub_para first">
          <div className="para_content inner_para_01">
          If you are a resident of the Supplemental States and have provided personal information to Miles, you can request:
          </div>
          
          <div className="miles_ui_privacy_title_02">The Right to Know</div>
          <div className="para_content inner_para_01">
          You have the right to request any or all of the following information relating to your personal information we have collected and disclosed in the last 12 months, upon verification of your identity:
          </div>

          <div className="miles_ui_privacy_sub_para first">
            <div className="miles_ui_para_with_dot">
              <span className="dot"></span> 
              <div className="miles_ui_para_with_dot_main_content">
              The specific pieces of information we have collected about you;
              </div>
            </div>
          </div>

          <div className="miles_ui_privacy_sub_para first">
            <div className="miles_ui_para_with_dot">
              <span className="dot"></span> 
              <div className="miles_ui_para_with_dot_main_content">
              The categories of Personal Information we have collected about you;
              </div>
            </div>
          </div>

          <div className="miles_ui_privacy_sub_para first">
            <div className="miles_ui_para_with_dot">
              <span className="dot"></span> 
              <div className="miles_ui_para_with_dot_main_content">
              The categories of sources of the Personal Information;
              </div>
            </div>
          </div>

          <div className="miles_ui_privacy_sub_para first">
            <div className="miles_ui_para_with_dot">
              <span className="dot"></span> 
              <div className="miles_ui_para_with_dot_main_content">
              The categories of Personal Information that we have disclosed to third parties for a business purpose, and the categories of recipients to whom this information was disclosed;
              </div>
            </div>
          </div>

          <div className="miles_ui_privacy_sub_para first">
            <div className="miles_ui_para_with_dot">
              <span className="dot"></span> 
              <div className="miles_ui_para_with_dot_main_content">
              The categories of Personal Information we have sold and the categories of third parties to whom the information was sold;
              </div>
            </div>
          </div>

          <div className="miles_ui_privacy_sub_para first">
            <div className="miles_ui_para_with_dot">
              <span className="dot"></span> 
              <div className="miles_ui_para_with_dot_main_content">
              The business or commercial purposes for collecting or selling the Personal Information
              </div>
            </div>
          </div>


          <div className="miles_ui_privacy_title_02">The Right to Request Deletion</div>
          <div className="para_content inner_para_01">
          You have the right to request the deletion of Personal Information we have collected from you, subject to certain exceptions.
          </div>

          <div className="miles_ui_privacy_title_02">The Right to Correct/Rectify</div>
          <div className="para_content inner_para_01">
          In certain circumstances, you have the right to request correction of any inaccurate personal information. Upon verifying the validity of a valid consumer correction request, we will use commercially reasonable efforts to correct your personal information as directed, taking into account the nature of the personal information and the purposes of maintaining your personal information.
          </div>

          <div className="miles_ui_privacy_title_02">The Right to Opt Out of the Sale of Personal Information</div>
          <div className="para_content inner_para_01">
          You have the right to opt-out of the disclosure of  your information to certain third parties.
          </div>

          <div className="miles_ui_privacy_title_02">The Right to Access</div>
          <div className="para_content inner_para_01">
          You have the right to access personal information that we may collect or retain about you. If requested, we shall provide you with a copy of your personal information which we collected as permitted by the applicable State Privacy Law.
          </div>

          <div className="para_content inner_para_01">
          You also have the right to receive your personal information in a structured and commonly used format so that it can be transferred to another entity (“data portability”).
          </div>

          <div className="miles_ui_privacy_title_02">The Right to Limit How Your Sensitive Personal Information is Disclosed</div>
          <div className="para_content inner_para_01">
          You have the right to limit how your sensitive personal information is disclosed or share with third parties.
          </div>

          <div className="miles_ui_privacy_title_02">The Right to NonDiscrimination</div>
          <div className="para_content inner_para_01">
          You have the right not to receive discriminatory treatment for exercising these rights. However, please note that if the exercise of these rights limits our ability to process Personal Information, we may no longer be able to provide you our products and services or engage with you in the same manner.
          </div>

          <div className="para_content inner_para_01">
          Note: We will not provide information if doing so would violate a duty of confidentiality owed to our customers or any applicable laws and regulations.
          </div>

          <div className="miles_ui_privacy_title_02">Right to Appeal</div>
          <div className="para_content inner_para_01">
          Users have the right to appeal our decisions and may contact us at <a href={`mailto:${currentSupportEmailAddress}`} target="_blank">{currentSupportEmailAddress}</a> to exercise this right.
          </div>
        </div>

       {/* 1 */}
        <div className="miles_ui_privacy_sub_para first without_dot">
          <div className="miles_ui_para_with_dot">
            <div className="miles_ui_para_with_dot_main_content without_dot">
              <span className="with_dot_title">Do Not Sell Requests. </span>
              We may share names, Mobile Ad IDs, location data, inferences from the location data and other information of our California customers with our Partners and other third parties which may be considered a sale under the State Privacy Laws You can opt out of this data sharing on our mobile application by visiting the Privacy page under the Settings tab.
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className="miles_ui_privacy_sub_para first without_dot">
          <div className="miles_ui_para_with_dot">
            <div className="miles_ui_para_with_dot_main_content without_dot">
              <span className="with_dot_title">Data Requests can be made by emailing <a href={`mailto:${currentSupportEmailAddress}`} target="_blank">{currentSupportEmailAddress}</a></span>.
              We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or the requestor’s authority to make the request. Questions about the data request process should be directed to <a href={`mailto:${currentSupportEmailAddress}`} target="_blank">{currentSupportEmailAddress}</a>.
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="miles_ui_privacy_sub_para first without_dot">
          <div className="miles_ui_para_with_dot">
            <div className="miles_ui_para_with_dot_main_content without_dot">
              <span className="with_dot_title">Verifying your identity is </span>
              required before we respond to your request. To verify your identity, we will collect basic personal information about you to match with our records. You may make data requests no more than twice in a 12-month period. 
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="miles_ui_privacy_sub_para first without_dot">
          <div className="miles_ui_para_with_dot">
            <div className="miles_ui_para_with_dot_main_content without_dot">
              <span className="with_dot_title">We will respond to verifiable requests within 45 days. </span>
              If we require more time, we will inform you of the reason and extension period in a written response. Any disclosures we provide will only cover a maximum of 12-month period preceding the receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable.
            </div>
          </div>
        </div>

        <div className="para_content inner_para_01">
        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request. We will not discriminate against you for exercising any of your rights under California law.
        </div>

        <div className="miles_ui_privacy_title_02">Financial Incentives Disclosure for California Residents</div>
        <div className="para_content inner_para_01">
        We may provide special offers and benefits to customers who choose to participate or sign up to receive communications from us or our Partners via email. The information we may request includes name, address, state, country, zip code, and email. The California Consumer Privacy Act’s implementing regulations define a “financial incentive” as a program, benefit or other offering “related to the collection, deletion, or sale of personal information.” Our collection of your personal information allows us to effectuate these programs. We estimate the value of personal information solely for purposes of the California Consumer Privacy Act and pursuant to the valuation options mandated by its regulations, to be reasonably related to the value of the offer or discount presented to you.
        </div>

        <br/>
        <div className="miles_ui_privacy_title_03">
        Contact Us
        </div>
        <div className="para_content inner_para_01">
        If you have any questions about this Notice, please contact us at:
        <br />

        <a href={`mailto:${currentSupportEmailAddress}`} target="_blank">{currentSupportEmailAddress}</a>
        <br />
        4064 Rivermark Pkwy,
        <br />
        Santa Clara, CA 95054
        </div>

        <div className="miles_ui_special_gap_03"></div>
      </div>

    </Styled_body_wrap>
  );
});

export default MilesWeb_SupplementalStatePrivacyNoticePage;
